<template>
  <!-- <el-pagination

    class="paging"
    @size-change="sizeChange"
    @current-change="currentChange"
    @prev-click="prevClick"
    @next-click="nextClick"
    :current-page.sync="pagingConfig.params.pageNum"
    :page-sizes="pagingConfig.pageSizes"
    :page-size="pagingConfig.params.pageSize"
    :layout="pagingConfig.layout"
    :total="paging.total">
  </el-pagination> -->
   <el-pagination
        class="paging"
        background
        :current-page.sync="pagingConfig.params.pageNum"
        :page-sizes="pagingConfig.pageSizes"
        :page-size="pagingConfig.params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="paging.total"
        @size-change="sizeChange"
        @current-change="currentChange"
    />
</template>

<script>
export default {
  name: 'dyPaging',
  props: {
    paging: {
      type: Object,
      default: () => ({
        params: {
          current: 1,
          pageSize: 10
        },
        total: 0
      })
    },
    cardPage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      pagingConfig: {
        params: {
          current: 1,
          pageSize: 10
        },
        pageSizes: [10, 25, 50],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  mounted () {
    this.$tools.jsonTreat(this.pagingConfig, this.paging)
    if (this.cardPage == 'card') {
      this.pagingConfig.params.pageSize = 9
      this.pagingConfig.pageSizes = [9, 18, 27]
    } else if (this.cardPage == 'zhongzhi_college') {
      this.pagingConfig.params.pageSize = 12
      this.pagingConfig.pageSizes = [12, 24, 36]
    }
  },
  methods: {
    sizeChange (val) {
      this.paging.params.pageSize = this.pagingConfig.params.pageSize = val
      this.paging.params.pageNum = this.pagingConfig.params.pageNum = 1
      this.currentChange(val)
    },
    currentChange () {
      this.paging.params.pageSize = this.pagingConfig.params.pageSize
      this.paging.params.pageNum = this.pagingConfig.params.pageNum
      this.$parent.getData()
      this.$emit('propsFn')
    },
    prevClick (val) {
      console.log('prev',val)
    },
    nextClick (val) {
      console.log('next',val)
    }
  }
}

</script>

<style lang="scss" scoped>
  .paging {
    text-align: center;
    margin-top: 10px;
    ::v-deep  .el-input__inner {
      height: 28px !important;
    }
    .el-pager li.active{
        background-color: #316fff !important;
    }  
  }
</style>
