export default {
  state: {
      currentlyPlay: {}
  },

  getters: {

  },

  mutations: {
  },

  actions: {
  },
};
