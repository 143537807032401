// 订单状态
const orderStatus = {
    0:{
        text:'待付款',
        picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/order/waitpay.png'
    },
    2:{
        text:'已完成付款',
        picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/order/paysuccess.png'
    },
    3:{
        text:'已关闭',
        picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/order/cancelpay.png'
    },
    4:{
        text:'已关闭',
        picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/order/closepay.png'
    },
    8:{
        text:'已关闭',
        picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/order/closepay.png'
    },
    9:{
        text:'已关闭',
        picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/order/closepay.png'
    }
};
  export default{
    orderStatus
}