
const routerArr = [
    {
        path: '/examDetails',
        name: 'examDetails',
        meta: { title:'考试详情' , login:true },
        component: () => import(/* webpackChunkName: 'learning' */ './page/examDetails.vue'),
    },
    {
        path: '/exam/examresults',
        name: 'examresults',
        meta: { title:'考试' , login:true },
        component: () => import(/* webpackChunkName: 'exam' */ './page/examresults.vue'),
    },
    {
        path: '/exam/resdExam',
        name: 'resdExam',
        meta: { title:'考试必读' , login:true },
        component: () => import(/* webpackChunkName: 'exam' */ './page/resdExam.vue'),
    },
    {
        path: '/exam/testPaper',
        name: 'testPaper',
        meta: { title:'试卷' , login:true },
        component: () => import(/* webpackChunkName: 'exam' */ './page/testPaper.vue'),
    },
    {
        path: '/exam/handPaper',
        name: 'handPaper',
        meta: { title:'考试结果' , login:true },
        component: () => import(/* webpackChunkName: 'exam' */ './page/handPaper.vue'),
    },
    {
        path: '/exam/testPaperDetails',
        name: 'testPaperDetails',
        meta: { title:'试卷详情' , login:true },
        component: () => import(/* webpackChunkName: 'exam' */ './page/testPaperDetails.vue'),
    },

]
export default routerArr
