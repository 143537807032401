import store from '@/store'
// 权限校验 根据某个key 判断元素是不是展示
export default {
    inserted(el, binding){
        
        const permissionKey = binding.value
        const frontendConfig = store.state.dynamic.frontendConfig

        const permission = frontendConfig.find(p => p.key === permissionKey)
        if (!permission || (permission.type === 'boolean' && permission.value !== true)) {
            const parent = el.parentNode
            console.log(el.parentNode);
            if (parent) parent.removeChild(el)
        }
    }
}