export default [
    {
        path: '/gpt',
        name: 'gpt',
        meta: {
            weight: 0,
            title: '',
            login: true,
            keepAlive: true,
        },
        component: () => import(/* webpackChunkName: 'gpt' */ './page/index.vue'),
    },
]
