<template>
  <el-dialog center :visible.sync="previewModalVisbile.show" :showClose="showClose" title="查看证书"
    customClass="definepreviewModal" :before-close="handleClosePreview">
    <div class="certificateModal">
      <div class="certificateModal-content">
        <img :src="previewUrl" alt="" class="certificateImage">
        <p class="name">{{ currentName }}</p>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="handleClosePreview" type="info">取消</el-button>
      <el-button @click="downLoad" v-if="isDownLoad" type="primary">下载</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'previewCertificate',
  props: {
    previewModalVisbile: {
      type: Object,
      default: (() => {
        return {
          show: false
        }
      }),
    },
    isDownLoad: {
      type: Boolean,
      default: false,
    },
    previewUrl: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    currentName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  created() {
    console.log(this.currentName, 'this.currentName')
  },
  methods: {
    downLoad() {
      this.$emit('downLoadCer');
    },
    handleClosePreview() {
      this.previewModalVisbile.show = false;
    }
  }
}
</script>
<style lang="scss" scoped> 
.certificateImage{
  width: 468px;
  height: 330px;
  object-fit: contain;  
}
.name{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-top: 16px;
}
::v-deep{
  .el-button{
    width: 148px;
    height: 44px;
    border: 0;
    border-radius: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .el-button--info{
    background: #F6F7F9;
    span{
      color: #666666;
    }
  }
  .el-button--primary{
    background-color: #1A72FF;
    span{
      color: #FFFFFF;
    }  
  }
  .definepreviewModal{
    width: 600px;  
    border-radius: 16px;
  }  
  .el-dialog__body{
    text-align: center;
  }
  .el-dialog__close{
    font-size: 20px;
    color: #333;  
  }
  .el-dialog__title{
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #08224D;
    line-height: 25px;
  }
}
</style>
