let base = require('js-base64').Base64;

const hostname = window.location.hostname
const port = window.location.port; // 本地开发有端口号


let envArr = ['testing1', 'testing2', 'testing3', 'fat', 'uat'];
let env = window.location.port ? 'testing3' : '';
envArr.forEach(d => {
  if (location.hostname.includes(d)) {env = d}
})
let API = env ? `${location.protocol}//hrss-api.${env}.wdeduc.com` : `${location.protocol}//hrss-api.wdeduc.com`;



let confUrl = port ? base.encode('wdn-saas-pc-qdjm.testing3.svc.k8s.bjo.wdcloud.cc') :  base.encode(hostname);

export default {
  env,
  API,
  confUrl
};