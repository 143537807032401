export default [
    {
        path: '/personal',
        name: 'personal',
        meta: {
            weight: 0,
            login: true,
        },
        component: () => import(/* webpackChunkName: 'personal' */ './page/index.vue'),
    }
]