// 时间转换
export default{
    dateFormat() {
        const date = new Date();
        const year = date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
        const day =
            date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const hours =
            date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes =
            date.getMinutes() < 10
                ? `0${date.getMinutes()}`
                : date.getMinutes();
        const seconds =
            date.getSeconds() < 10
                ? `0${date.getSeconds()}`
                : date.getSeconds();
        // 拼接
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    /**
     *@description:将秒转换为小时   例如360s=> 1.00
    *@params1: time:秒数
    *@params2: fixed:保留的小数点，默认2位
    
    */
    transSecondstoHour(time,fixed=2) {
      const tempTime = (time / 3600).toFixed(fixed);
      return (tempTime*1==0)?0:tempTime
    }
}