let errorPage =[
    {
        path: '/401',
        name: 'error_401',
        meta: {login: false},
        component: () => import(/* webpackChunkName: 'error_401' */ './401.vue'),
      },
      {
          path: '/500',
          name: 'error_500',
          meta: {login: false},
          component: () => import(/* webpackChunkName: 'error_500' */ './500.vue'),
      },
      {
          path: '*',
          name: 'error_404',
          meta: {login: false},
          component: () => import(/* webpackChunkName: 'error_404' */ './404.vue')
      },
]
export default errorPage