import Axios from 'axios'
import URL from '../config/url'
import { Toast } from 'vant'
import store from '../store'

let controller = new AbortController()
console.log('🚀 ~ file: axios2.js ~ line 7 ~ controller', controller)

const baseURL = URL.API
// const baseURL = URL.GPTAPI;
const service = Axios.create({
    withCredentials: true,
    baseURL,
    signal: controller.signal,
    responseType: 'stream',
    // onDownloadProgress: function (progressEvent) {
    //     setData(progressEvent.event.currentTarget.responseText)
    // },
})
// 请求拦截器
service.interceptors.request.use(
    config => {
        config.headers['token'] = localStorage.getItem('token') || ''
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// 响应拦截器
service.interceptors.response.use(
    config => {
        return config
    },
    err => {
        console.error(err)
        return Promise.reject(err)
    }
)

let stackArr = []
let isPoint = false
function loop(t = 0) {
    console.log(stackArr, 'stackArr')
    if (stackArr.length > 0) {
        setTimeout(() => {
            Toast({
                message: stackArr[0],
                duration: 2000,
                onClose: () => {
                    stackArr.shift()
                    if (stackArr.length) {
                        loop(2000)
                    } else {
                        isPoint = false
                    }
                },
            })
        }, t)
    }
}
/* eslint-disable */
function API(config) {
    const { url = '', data = null, params = null, headers = {}, method = 'get', timeout = 30000, isToast = true } = config || {}
    return new Promise((resolve, reject) => {
        service({
            method, // 请求方式
            url, // 请求路径
            timeout, // 请求超时
            headers: {
                // "content-type": "application/json",
                ...headers,
            }, // header体
            data, // body参数
            params, //  URL参数
            isToast, //是否显示toast提示
        })
            .then(async res => {
                if (res.data.code) {
                    // if (!stackArr.includes(res.data.msg)) {
                    //     stackArr.push(res.data.msg)
                    // }

                    if (!isPoint) {
                        isPoint = true
                        if (isToast) {
                            loop()
                        }
                    }
                    if (+res.data.code === 303 || +res.data.code === 10000) {
                        store.dispatch('resetToken').then(res => {
                            location.reload()
                        })
                    }
                }
                resolve({
                    ...res.data,
                    headers: res.headers,
                })
            })
            .catch(err => {
                reject(err)
                if (err && err.message) {
                    let msg = '网络异常，请检查网络'
                    if (err.message === 'Network Error') {
                        msg = '网络异常，请检查网络'
                    }
                    if (err.message.indexOf('timeout') !== -1) {
                        msg = '网络超时'
                    }
                    if (!stackArr.includes(msg)) {
                        stackArr.push(msg)
                    }

                    if (!isPoint) {
                        isPoint = true
                        loop()
                    }
                }
                return err
            })
    })
}

export default API
