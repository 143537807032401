export default [
  {
      path: '/operationHomeWork',
      name: 'operationHomeWork',
      meta: {
          weight: 0,
          login: true,
      },
      component: () => import(/* webpackChunkName: 'operationHomeWork' */ './page/index.vue'),
  },
]