const courseLabelType = {
    1:{
      text:'必修课'
    },
    2:{
      text:'选修课'
    },
    3:{
      text:'公开课'
    }
  }
  export default {
    courseLabelType,
  }