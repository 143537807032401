<template>
    <div v-if="ModalVisbileAuth" class="auth-box">
        <el-dialog
            :visible.sync="ModalVisbileAuth"
            width="464px"
            height="366px"
            :showClose="showClose"
            :before-close="handleCloseAuth"
            >
            <div class="authModal">
                <div  @click="handleCloseAuth" class="closeBtn">
                    <svg-icon icon-class="close" class-name="closeImg"></svg-icon>
                </div>
                <p class="authModal-title">实名认证</p>
                <p>请使用微信或手机浏览器扫描下方二维码，完成实名认证。</p>
                <!-- <img src="../assets/images/wechat.jpg" alt="" class="er"> -->
                <div id="qrcodeAuth" class="er" ref="qrcode"></div>
                <p>个人隐私信息安全保障中</p>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import QRCode  from "qrcodejs2"
export default {
    name:'authGuide',
    props:{
        ModalVisbileAuth:{
            type:Boolean,
            default:false
        },
        wechatErInfo:{
            type:Object,
            default: () => ({})
        }
    },
    data(){
        return{
            QRCode: null,
            showClose:false
        }
    },
    watch:{
        'ModalVisbileAuth'(){
            if(this.ModalVisbileAuth){
                this.$nextTick(() => {
                    this.qrcode()
                })
            }else{
                this.QRCode = null
                this.$refs.qrcode.innerHTML = ''
            }
        }
    },
    methods:{
        handleCloseAuth(){
            console.log('fdfdfdfdf');
            this.$emit('handleCloseAuth')
        },
        qrcode () {
            if (this.QRCode) return
            let domainArr = localStorage.getItem('domain')
            let domainUrl = domainArr && JSON.parse(domainArr).find(item => item.frontend_template_id == 4).domain
            this.QRCode = new QRCode('qrcodeAuth', {
                width: 148,
                height: 148,        // 高度
                text:  `${window.location.protocol}//${domainUrl}/login?phone=${this.wechatErInfo.phone}&redirect=/idcard`,   // 二维码内容
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.auth-box {
	::v-deep {
		.el-dialog__header {
			padding: 0;
			padding-top: 10px;
		}
	}
	.authModal {
		position: relative;
		text-align: center;
		.closeBtn {
			position: absolute;
			top: -16px;
			right: 4px;
			cursor: pointer;
			.icon {
				font-size: 26px;
				color: #999;
			}
		}
		p {
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			color: #666;
			&.authModal-title {
				font-size: 24px;
				line-height: 34px;
				color: #1b2257;
				margin-bottom: 16px;
				font-weight: 500;
				font-family: PingFangSC-Medium, PingFang SC;
			}
		}
		.er {
			width: 160px;
			height: 160px;
			margin: 26px auto 16px;
			border: 1px solid #f6f6f6;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			position: relative;
			&::after {
				width: 14px;
				height: 14px;
				position: absolute;
				content: '';
				top: -1px;
				left: -1px;
				background: url("../assets/images/common/qrcodeShang.png") no-repeat center center;
			}
			&::before {
				width: 14px;
				height: 14px;
				position: absolute;
				content: '';
				bottom: -1px;
				right: -1px;
				background: url("../assets/images/common/qrcodeXia.png") no-repeat center center;
			}
		}
	}
}

</style>
