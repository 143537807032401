const urlName = "/standard-saas/v1";
export default API => ({
    // 订单列表
    myOrderList ({params,data}) {
        return API({ 
            url: `${urlName}/order/user/myOrderList`,
            method: 'post',
            params,
            data
        })
    },
    // 订单页面获取订单详情
    getOrderByNo(orderNo) {
        return API({
            url: `${urlName}/order/detail?orderNo=${orderNo}`,
        });
    },
      // 查询订单状态
    getOrderStatus(id) {
        return API({
            url: `/standard-saas/v1/order/user/getOrderStatus?orderNo=${id}`,
        });
    },
    // 收银台支付预下单
    prePayOrde({data, params}) {
        return API({ url: `/standard-saas/v1/order/user/prePayOrder?payChannel=1`, method:'post', params, data });
    },
    // 收银台支付预下单
    launchPay({data, params}) {
        return API({ url: `/pay/cashier/launchPay?orderNo=${data.orderNo}&scene=3`, method:'post', params, data });
    },
    findAndSetPayStatus(payNo) {
        return API({ url: `/pay/v1/pay/findAndSetPayStatus?payNo=${payNo}`, method:'post'});
    },
    // 取消订单
    cancelOrder(id) {
        return API({
            url: `/standard-saas/v1/order/user/cancelOrder?orderNo=${id}`,
        });
    },
})
