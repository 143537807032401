export default [
    {
        path: '/',
        name: 'home',
        meta: {
            weight: 0,
            login: true,
            gpt:true,//配置Ai助手
            gptType:1,//1:首页，2：课程详情
        },
        component: () => import(/* webpackChunkName: 'home' */ './page/index.vue'),
    }
]