export default API => ({
   /**
    *@params1: type类型:1-首页全局bot 2-任务页bot
    *@params2: task_ucode 任务ucode (type=2时必填)
    *@description:发送对话
   */
    chatGpt(data) {
        return API({
            url: `/ai/v1/chat_gpt`,
            method: 'post',
            data,
        })
    },
    /**
     *@params1: 参数1  reaction 1-赞 2-踩
     *@params2: 参数2  feedback 反馈信息
     *@params3: 参数3  sn 对话标识
     *@description:Ai回答的赞，踩
    */
    evaluate_llm(data) {
        return API({
            url: `/ai/v1/evaluate_llm`,
            method: 'post',
            data,
        })
    },
    // 课程推荐
    courseRecommend(data) {
        return API({
            url: `/ai/v1/course_recommend`,
            method: 'post',
            data,
        })
    },
    // 获取历史消息记录
    getHistory(params) {
        return API({
            url: `/ai/v1/get_history`,
            method: 'get',
            params,
        })
    },
    // 获取推荐话术
    getQuestionRecommend(params) {
        return API({
            url: `/ai/v1/get_question_recommend`,
            method: 'get',
            params,
        })
    },
    // 清除数据
    clearContext(data) {
        return API({
            url: `/ai/v1/clear_context`,
            method: 'post',
            data,
        })
    },
    // 点击预设问题
    preset_question(data) {
        return API({
            url: `/ai/v1/preset_question`,
            method: 'post',
            data,
        })
    },
    // 是否显示预设问题
    show_preset_question(data) {
        return API({
            url: `/ai/v1/show_preset_question`,
            method: 'post',
            data,
        })
    },
    // 获取资源信息(视频标记，字幕)
    getGptResourceInfo(data) {
        return API({
            url: `/ai-qa/v1/getVideoLightPoints` ,
            method:'post',
            data
        });
    },
    getVideoSubtitle(data) {
        return API({
            url: `/ai-qa/v1/getVideoSubtitle` ,
            method:'post',
            data
        });
    },
})
