const urlName = "/standard-saas/v1";
export default (API) => ({
  // 获取作业详情
  getDetail({data,params}) {
    return API({
      url: `${urlName}/operationHomeworkUserX/detail`,
      method: "get",
      data,
      params
    });
  },
  // 保存作业草稿
  saveDraft({data,params}) {
    return API({
      url: `${urlName}/operationHomeworkUserX/saveDraft`,
      method: "post",
      data,
      params
    });
  },
  // 提交作业
  submitHomeWork({data,params}) {
    return API({
      url: `${urlName}/operationHomeworkUserX/submit`,
      method: "post",
      data,
      params
    });
  },
  // 作业审批列表
  getHomeWorkList({data,params}) {
    return API({
      url: `${urlName}/operationHomeworkUserX/page`,
      method: "post",
      data,
      params
    });
  },
  // 获取实操作业信息详情
  getHomework(id) {
    return API({
      url: `/standard-saas/v1/operationHomework/${id}`,
      method: "get",
    });
  },
  // 获取作业内容
  getHomeWorkDetailInfo(params) {
    return API({
      url: `/standard-saas/v1/operationHomeworkUserX/${params}`,
      method: "get",
    });
  },
  //作业-审批记录
  approvalRecord({params}) {
    return API({
      url: `/standard-saas/v1/operationHomeworkAuditRecord/`,
      method: "get",
      params
    });
  },
  // 审批作业
  approval({data,params}) {
    return API({
      url: `/standard-saas/v1/operationHomeworkUserX/approval`,
      method: 'post',
      data,
      params
    })
  },
  getPrivateResUrl({data,params}) {
    return API({
      url: `/common-services/v1/resAccessToken/getPrivateResUrl`,
      method: 'get',
      data,
      params
    })
  },
   // oss 上传文档的凭证
  getAliyunDocConf() {
    return API({
      url: '/common-services/v1/resource/getUploadDocVoucher',
      method: 'post'
    })
  },
});
