export default API => ({
  // 资讯列表
  getArticleList({
    data,
    params
  }) {
    return API({
      url: `/cms/v1/article/articleList`,
      params,
      data,
      method:'get'
    });
  },
  findById({
    data,
    params
  }) {
    return API({
      url: `/cms/v1/article/findById`,
      params,
      data,
      method:'get'
    });
  },

  
})