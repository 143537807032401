
const routerArr = [
  {
      path: '/course/play',
      name: 'coursePlay',
      meta: { title:'视频' , login:true, gptType: 4 },
      component: () => import(/* webpackChunkName: 'coursePlay' */ './page/indexNew.vue'),
  }
]
export default routerArr
