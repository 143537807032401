<!-- 
 * @description: 标签
 * @fileName: wd-tag.vue 
 * @date: 2024-09-06 18:55:02
!-->
<template>
    <div class="wdTag" v-if="item" :style="`background:url(${ConstData.courseSource[+item.type].picUrl}) no-repeat left top;background-size:cover`">
        <p class="text" :class="ConstData.courseSource[+item.type].type">{{ConstData.courseSource[+item.type].text}}</p>
    </div>
</template>
<script>
export default {
    name:'wd-tag',
    props:{
        item:{
            type:Object
        }
    }
}
</script>
<style lang="scss" scoped>
.wdTag{
    position: absolute;
    top: 0px;
    left: 0px;
    width:62px;
    height:30px;
    .text{
        color:#fff!important;
        font-size:12px;
        padding-left:10px;
        line-height:12px;
        margin-top:6px;
        &.employ{
            padding-left:4px;
        }
    }
}
    
</style>