export default API => ({
  // 观看课程信息保存
  postCourseLearningLogSaveLog({
    data,
    params
  }) {
    return API({
      url: `/standard-saas/v1/taskLearningLog/saveLog`,
      method: 'post',
      params,
      data
    });
  },
  /**
   * 获取任务里下一个课程
   */
  getNextCourseInfo({
    data,
    params
  }) {
    return API({
      url: `/standard-saas/v1/taskUser/getNextCourseInfo`,
      params,
      data
    });
  },
  //任务规则
  findTaskRule(taskId) {
    return API({
      url: `/standard-saas/v1/taskUser/findTaskRule?taskId=${taskId}`,
    })
  },
  //获取目录
  findCourseItemByCourseId({
    data,
    params
  }) {
    return API({
      url: `/standard-saas/v1/open/courseItem/getValidCourseItemInfo`,
      params,
      data
    });
  },
})