/*
 * @Author: felixli
 * @Date: 2023-11-10 10:03:58
 * @Description:
 */
export default [
  {
    path: "/learn/list",
    name: "learnList",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(/* webpackChunkName: 'learnList' */ "./page/index.vue"),
  },
  {
    path: "/learning",
    meta: {
      weight: 0,
      login: true,
    },
    redirect: '/learn/list' // 重定向到首页
  },
  {
    path: "/learn/course/detail",
    name: "learnCourseDetail",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'learnCourseDetail' */ "./page/courseDetail.vue"
      ),
  },
  {
    path: "/learn/questionnaire",
    name: "learnQuestionnaire",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'learnQuestionnaire' */ "./page/questionnaire.vue"
      ),
  },
  {
    path: "/learn/StageMap",
    name: "learnStageMap",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(/* webpackChunkName: 'learnStageMap' */ "./page/StageMap.vue"),
  },
  {
    path: "/learn/task/detail",
    name: "LearnTaskDetail",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(/* webpackChunkName: 'learnStageMap' */ "./page/taskDetail.vue"),
  },
];
