/**
 * @file
 * @author tanghao
 * @date 2019-06-19
 */

import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import pages from './module/pages';
import coures from './module/coures';
import dynamic from '@wd/lowcode-ss-pc/plugin/store/index.js'; 

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        theme:'',
        courseCenterSearchName:'',
        pageConfigs: [],
        testPaper: '',
        subUserInfo:JSON.parse(localStorage.getItem('subUserInfo')) || {},
        userInfo:JSON.parse(localStorage.getItem('userInfo')) || {},
        areaList:[]
    },
    mutations: {
        //主题
        setTheme(state, payload){
            state.theme = payload
        },
        courseCenterSearchName(state, payload){
          state.courseCenterSearchName = payload
        },
        subUserInfo(state, payload){
          state.subUserInfo = payload;
        },
        userInfo(state, payload){
          state.userInfo = payload;
        },
        setPageConfigs(state, payload) {
            state.pageConfigs = payload;
        },
        // 机构级别
        saveSubUserInfo(state, payload) {
            state.subUserInfo = payload;
        },
        updateSubUserInfo(state,payload){
            state.subUserInfo[payload.key] = payload.value
        },
        // 城市列表
        saveCityTree(state,payload){
            state.areaList = payload
        }
    },
    actions: {
    },
    getters: {
    },
    modules: {
        app,
        dynamic,
        pages,
        coures
    },
});
