
const routerArr = [
    {
        path: '/orderPage',
        name: 'orderPage',
        meta: { title:'确认订单' , login:true },
        component: () => import(/* webpackChunkName: 'order' */ './order.vue'),
    },


]
export default routerArr
