 const gptTypeRecommend = {
    //  1:首页推荐 2：课程详情推荐
   1:[
       {
           question:'高效职业化的沟通管理课程中讲了什么？',
       },
       {
           question:'大客户关系维系要怎么做？',
       },
       {
           question:'除了抖音,还有哪些营销渠道？',
       }
   ],
   2:[
       {
           question:'课程主要讲了什么？',
       },
       {
           question:'请把课程大纲发给我',
       }
   ]
}
export default {
  gptTypeRecommend
}