import permission from './permission'

const install = function(Vue) {
  Vue.directive('ai-permission', permission)
}

if (window.Vue) {
  window['ai-permission'] = permission
  Vue.use(install); // eslint-disable-line
}

permission.install = install
export default permission