<template>
    <div class="footer">
      <div class="content">
        <div class="contentItem" v-for="(item,index) in pageEnd.middleTextContentList" :key="index">
          <p class="title">{{item.titleName}}</p>
          <p class="addTxt">
            {{ item.titleContent }}
          </p>
        </div>
        
        <div class="bottom" v-html="pageEnd?.copyright"></div>
      </div>
      
    </div>
</template>

<script>
export default {
  data(){
    return{
      pageEnd:null,
    }
  },
  created(){
    let setTempConfigs = localStorage.getItem('setTempConfigs') && JSON.parse(localStorage.getItem('setTempConfigs'));
    console.log(setTempConfigs,'setTempConfigs===')
    this.pageEnd = JSON.parse(setTempConfigs.pageEnd);
    
  },
  methods:{

  }
}
</script>

<style lang="scss" src="../../assets/css/footer.scss" scoped>

</style>