const urlName = "/standard-saas/v1";
export default (API) => ({
  // 列表初始化
  findTaskList({ data, params }) {
    return API({
      //学习列表
      url: `${urlName}/taskUser/findTaskList`,
      params,
      data,
    });
  },
  // 列表二次渲染 返回学习信息
  findTaskLearningInfo({ data, params }) {
    return API({
      //学习列表
      url: `${urlName}/taskUser/findTaskLearningInfo`,
      params,
      data,
    });
  },
  // 详情页二次渲染
  taskDetailExt({ data, params }) {
    return API({
      //学习列表
      url: `${urlName}/taskUser/taskDetailExt`,
      params,
      data,
    });
  },
  // 统计学习时长
  getAllLearnedData() {
    return API({
      url: `/standard-saas/v1/taskLearningLog/getAllLearnedData`,
      method: "get",
    });
  },
  /**
   * 课程快速学习
   */
  quickLearn({ data, params }) {
    return API({
      url: `${urlName}/taskLearningLog/quickLearn`,
      params,
      data,
    });
  },
  // 学习记录列表
  getMyTaskList({ data, params }) {
    return API({
      url: `${urlName}/task/open/myTaskList`,
      params,
      data,
    });
  },

  // 我的学分
  getMyCreditList({ data, params }) {
    return API({
      url: `${urlName}/task/open/myCreditList`,
      params,
      data,
    });
  },
  getMyCreditStatistics({ data, params }) {
    return API({
      url: `${urlName}/task/open/myCreditStatistics`,
      params,
      data,
    });
  },

  // 线下实训 根据培训id获取自己打卡记录
  findSelfByTrainId({ data, params }) {
    return API({
      url: `${urlName}/offlineTrainSignRecord/findSelfByTrainId`,
      params,
      data,
      method: "post",
    });
  },
  // 任务列表
  taskOpenList({ data, params }) {
    return API({
      url: `${urlName}/inductionTraining/open/list`,
      params,
      data,
    });
  },
  // 任务学习进度
  findTaskLearningInfoTask({ data, params }) {
    return API({
      url: `${urlName}/inductionTraining/open/findTaskLearningInfo`,
      params,
      data,
    });
  },

  // 任务目录
  taskDetailExts({ data, params }) {
    return API({
      url: `${urlName}/inductionTraining/open/taskDetailExt`,
      params,
      data,
    });
  },
  // 任务学习详情
  taskDetailWithoutLogin({ data, params }) {
    return API({
      url: `${urlName}/inductionTraining/open/taskDetailWithoutLogin`,
      params,
      data,
    });
  },
});
