import sensors from "sa-sdk-javascript";

let envArr = ["testing1", "testing2", "testing3", "fat", "uat"];
let env = window.location.port ? "testing3" : "prod";
envArr.forEach((d) => {
  if (window.location.hostname.includes(d)) {
    env = d;
  }
});
let project = "default";
if (process.env.NODE_ENV === "production" && env === "prod") {
  project = "production";
}

sensors.init({
  server_url: `https://weidongyunjiaoyu.datasink.sensorsdata.cn/sa?token=79fdf2ad32bcc11b&project=${project}`,
  is_track_single_page: true,
  show_log: false,
  app_js_bridge: true,
  heatmap: {
    clickmap: "default",
    scroll_notice_map: "default",
  },
});

function getUserInfo(key) {
  const userData = localStorage.getItem("userInfo");
  let userInfo = null;
  if (userData) {
    userInfo = JSON.parse(userData);
    return userInfo[key];
  }
  return "";
}
sensors.registerPage({
  platform_type: "Web",
  product_name: "学员端",
  company_name: "",
  institution_id: function () {
    const UserBranchList =
      localStorage.getItem("UserBranchList") &&
      JSON.parse(localStorage.getItem("UserBranchList"));
    return UserBranchList[0].ucode;
  },
  institution_name: function () {
    const UserBranchList =
      localStorage.getItem("UserBranchList") &&
      JSON.parse(localStorage.getItem("UserBranchList"));
    return UserBranchList[0].name;
  },
  saas_name: function () {
    const saasName = sessionStorage.getItem("saasName");
    return saasName;
  },
  user_type: function () {
    // 用户角色
    return "学员";
  },
  phone_number: function () {
    return getUserInfo("phone");
  },
  user_real_name: function () {
    return getUserInfo("name");
  },
  saas_id: function name() {
    //所属租户ID
    const saasId = sessionStorage.getItem("saasId");
    return saasId;
  },
  company_id: function () {
    // 企业域名所属机构ID
    const branchId = sessionStorage.getItem("branchId");
    branchId && sensors.identify(branchId, true);
    return branchId || null;
  },
  // company_name: function () { // 企业域名所属机构名称
  //     const company_name = localStorage.getItem('company_name')
  //     return company_name
  // },
  is_login: function () {
    // 是否登录
    const token = localStorage.getItem("token");
    return token ? 1 : 0;
  },
});

sensors.quick("autoTrack"); // 首次触发页面加载事件 $pageview
// sensors.quick('isReady',function(){
// 	console.log(sensors.quick('getAnonymousID'))
// })
export default sensors;
// 自定义事件埋点
// this.$sensors.track('ViewSupplyChainDetails'[, param][,callback])
