const urlName = '/standard-saas/v1/ai'


export default API => ({
  // 人脸对比
    postcamera({ params, data }) {
        return API({ url: `/standard-saas/v1/humanFace/comparison`, method:'post', params, data,isToast:false });
    },
    judgefaceauth({ params, data }) {
        return API({ url: `/standard-saas/v1/user/oauth/isRealNameAuthentication`, method:'post', params, data });
    },
    faceverify({ params, data }) {
        return API({ url: `${urlName}/oauth/faceverify`, method:'post', params, data});
    },
    idcardAffirm({ params, data }) {
        return API({ url: `${urlName}/oauth/idcardAffirm`, method:'post', params, data});
    },
    idcardDiscern({ params, data }) {
        return API({ url: `${urlName}/oauth/idcardDiscern`, method:'post', params, data});
    },
    getvirefytoken({ params, data }) {
        return API({ url:`${urlName}/token/getVerifyToken`, method:'post', params, data });
    },
    // 记录用户授权人脸比对照片保存
    // POST
    // query参数：taskId
    authSaveFace({ params, data }) {
        return API({ url:`/standard-saas/v1/humanFace/authSaveFace`, method:'post', params, data });
    },
    // 获取用户授权人脸比对照片保存记录
    // GET
    // query参数：taskId
    // 返回：1-已授权，0-未授权
    getAuthSaveFace({ params, data }) {
        return API({ url:`/standard-saas/v1/humanFace/getAuthSaveFace`, method:'get', params, data });
    },
})