const Routers = require.context("./../modules/", true, /\.router\.js$/);

let RouterArr = [];

Routers.keys().forEach((key) => {
  RouterArr = [...RouterArr, ...Routers(key).default];
});

RouterArr.forEach((item) => {
  item.meta.highlight = item.path;
});
const route = [
  ...RouterArr, // saas定制的页面,以及需要路由覆盖的页面
];
console.log(121212, route);
export default route;
