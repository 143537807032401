export default [
  {
    path: "/lecturer/list",
    name: "lecturerList",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(/* webpackChunkName: 'lecturerList' */ "./page/index.vue"),
  },
  {
    path: "/lecturer/detail",
    name: "lecturerDetail",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(/* webpackChunkName: 'lecturerList' */ "./page/detail.vue"),
  },
];
