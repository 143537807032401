import API from '../libs/axiosGpt'

let apiArr = {}
const Routers = require.context('./modulesGpt/', true, /\.js$/)

Routers.keys().forEach(key => {
    let urlArr = key.split('/')
    let apiKey = urlArr[urlArr.length - 1].split('.')[0]
    apiArr[apiKey] = {
        ...apiArr,
        ...Routers(key).default(API),
    }
})

export default {
    ...apiArr,
}
