export default [
    {
        path: '/information/list',
        name: 'informationList',
        meta: {
            weight: 0,
            login: true,
        },
        component: () => import(/* webpackChunkName: 'information' */ './page/list.vue'),
    },
    {
        path: '/information/detail',
        name: 'informationDetail',
        meta: {
            weight: 0,
            login: true,
        },
        component: () => import(/* webpackChunkName: 'information' */ './page/detail.vue'),
    }
]