const urlName = '/standard-saas/v1';
export default API => ({
    // 考试列表
    findUserExams({ data, params }) {
        return API({
            url: `/standard-saas/v1/userExam/findUserExams`,
            params,
            data
        });
    },
    // 获取考试基础信息
    findBasicExamById({params,data}){
        return API({
            url:`${urlName}/exam/findBasicExamById`,
            method:'get',
            params,
            data
        })
    },
    // 单次考试结果-统计
    findUserPaperSurface1(userPaperId){
        return API({
            url:`${urlName}/userPaper/findUserPaperSurface?userPaperId=${userPaperId}`,
            method:'get',
        })
    },
    // 新接口 试卷结果
    getUserPaperSurface({data,params}){
        return API({
            url:`${urlName}/userPaper/getUserPaperSurface`,
            method:'get',
            params,
            data
        })
    },
    // 考试中-试题信息
    findQuestForExam(userPaperId,examId){
        return API({
            url:`${urlName}/userPaper/findQuestForExam?userPaperId=${userPaperId}&examId=${examId}`,
            method:'get',
        })
    },
    // 整体提交试卷答案
    commitAllPaper(data){
        return API({
            url:`${urlName}/userPaper/commitAllPaper`,
            method:'post',
            data
        });
    },
    // 加入指定考试，返回用户试卷ID
    joinExam({data,params}){
        return API({
            url:`${urlName}/exam/joinExam`,
            data,
            params,
            method:'get',
        })
    },
    // 单次考试结果-详情
    findUserPaperInfo(userPaperId){
        return API({
            url:`${urlName}/userPaper/findUserPaperInfo?userPaperId=${userPaperId}`,
            method:'get',
        })
    },
    // 查询训练营考试详情
    findExamListByIds(bussinessId, examId, type) {
        return API({ //简介
            url: `${urlName}/exam/findExamListByIds?bussinessId=${bussinessId}&examId=${examId}&type=${type}`,
            method:'get',
        });
    },
    // 查询指定考试历史记录
    findUserPaperListById(bussinessId, examId, type) {
        return API({ //简介
            url: `${urlName}/userPaper/findUserPaperListById?bussinessId=${bussinessId}&examId=${examId}&pageNum=1&pageSize=999&type=${type}`,
            method:'get',
        });
    },
    // 用户切屏保存接口
    userSwitchScreen(userPaperId) {
        return API({
            url: `${urlName}/userPaper/monitor/userSwitchScreen?userPaperId=${userPaperId}`,
            method:'get',
        });
    },
    findUserPaperSurface(params) {
        return API({ url: '/standard-saas/v1/userPaper/findUserPaperSurface', method: 'get', params});
    },
    // 考试中直播
    queryLinkLiveData(params) {
      return API({ url: '/standard-saas/v1/userPaper/monitor/queryLinkLiveData', method: 'get', params});
    },
    // 轮询:是否有违规,是否强制交卷,是否有警告等
    queryMsgInform(params) {
      return API({ url: '/standard-saas/v1/userPaper/monitor/queryMsgInform', method: 'get', params});
    },
    // 管理员强制交卷
    adminForceCommit(params) {
      return API({ url: '/standard-saas/v1/userPaper/monitor/adminForceCommit', method: 'get', params});
    },
    // 管理员警告
    adminAlert(data) {
      return API({
        url: '/standard-saas/v1/userPaper/monitor/adminAlert',
        method: 'post',
        data
      });
    },
    // 考试监管保存抓拍
    saveCapture(data) {
      return API({
        url: '/standard-saas/v1/monitorCaptureRecord/saveCapture',
        method: 'post',
        data
      });
    },
    // 获取人脸
    getFace() {
      return API({ url: '/standard-saas/v1/humanFace/getFe', method: 'get'});
    },
    //生成h5二维码接口上传
    saveTKData(params) {
        return API({
            url: `/standard-saas/v1/userPaper/monitor/saveTKData`,
            params,
            method:'get',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },

    // 直播连接成功记录接口
    linkLiveSucc(params) {
        return API({
            url: `/standard-saas/v1/userPaper/monitor/linkLiveSucc`,
            params,
            method:'get',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },



    // 轮训:获取拉流地址,直播是否连接
    queryLinkStatusAndPhoneLive(params) {
        return API({
            url: `/standard-saas/v1/userPaper/monitor/queryLinkStatusAndPhoneLive`,
            params,
            method:'get',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },

    // 轮询:获取拉流地址,是否开启直播
    examDetailData(params) {
        return API({
            url: `/standard-saas/v1/exam/findById`,
            params,
            method:'get',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },
    // 直播异常、正常上报
    linkLiveStatus(params) {
      return API({
        url: `/standard-saas/v1/userPaper/monitor/linkLiveStatus`,
        params,
        method:'get',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
    },


    // 实操考试 获取考试环境url
    getDrillUrlForExam(data) {
      return API({
        url: `/standard-saas/v1/drill/getDrillUrlForExam?courseId=${data.courseId}&labId=${data.labId}&userPaperId=${data.userPaperId}`,
        method: 'post'
      });
    },
    // 实操考试基本信息
    findQuestAndLabidForExam(userPaperId) {
        return API({
            url: `/standard-saas/v1/userPaper/findQuestAndLabidForExam?userPaperId=${userPaperId}`,
            method:'get',
        });
    },

    // 轮询，记录在线人数
    sendHeartbeat(params){
      return API({
        url: `/standard-saas/v1/data/sendHeartbeat`,
        method:'get',
        params
      });
    },
    // 考前监管获取小程序二维码
    getWxCode(tokens) {
      return API({
        url: `/standard-saas/v1/wx/getwxacodeunlimit?tokens=${tokens}&t=${new Date().getTime()}`,
        method:'post',
        data: {tokens: tokens}
      });
    },
    // 呼叫考官动作
    saveCall(data){
      return API({
        url: `/standard-saas/v1/examCall/save`,
        method:'post',
        data
      });
    },
    // 呼叫考官查询状态
    findByStatus(params){
      return API({
        url: `/standard-saas/v1/examCall/findByStatus`,
        method:'get',
        params
      });
    },
    // pc开启动混流
    joinMix(params) {
      return API({
        url: `/standard-saas/v1/userPaper/monitor/joinMix`,
        method:'get',
        params
      });
    },
    // 操作手册
    download(){
      return API({
        url: `/standard-saas/v1/userExam/download`,
        method:'get',
      });
    }
})
