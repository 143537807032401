export default [
    {
        path: '/course/list',
        name: 'courseList',
        meta: {
            weight: 0,
            login: false,
        },
        component: () => import(/* webpackChunkName: 'course' */ './page/list.vue'),
    },
    {
        path: '/course/detail',
        name: 'courseDetail',
        meta: {
            weight: 0,
            login: false,
            gpt:true, //配置Ai助手
            gptType:2
        },
        component: () => import(/* webpackChunkName: 'course' */ './page/detail.vue'),
    }
]