import Vue from "vue";
import "./libs/element";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./config";
import api from "./api";
import gptApi from "./api/gptIndex";
import dayjs from "dayjs";

import Templates from "./libs/template";
import Tools from "@stroll/tools";

import "./styl/app.scss";
import "video.js/dist/video-js.css";
config.productionTip = process.env.NODE_ENV === "development";
import "promise-polyfill/src/polyfill";
import dateFormat from "./libs/dateFormat"; // 当前时间转换
import sensors from "./utils/sensors";
Vue.prototype.$sensors = sensors;
import question from "@wd/questionJJ";
// import tempRender from "@wd/news-ss-temp";
import "@wd/questionJJ/lib/questionJJ.css";
import SDK from "@wd/lowcode-ss-pc";
//禁止修改此文件
import "./assets/icons";
import DataUtil from "./libs/data.js";
import permission from "@/directive/permission";

Vue.use(question);
// Vue.use(tempRender);
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === "signOutStorage") {
    // 创建一个StorageEvent事件
    var newStorageSignOut = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageSignOut.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
        // 派发对象
        window.dispatchEvent(newStorageSignOut);
      },
    };
    return storage.setItem(key, newVal);
  }
};

/**
 * @description 全局注册应用配置
 */
Vue.use(Templates);
Vue.directive("permission", permission);
Vue.prototype.ConstData = DataUtil;
Vue.prototype.$config = config;
Vue.prototype.$tools = Tools;
Vue.prototype.$api = api;
Vue.prototype.$gptApi = gptApi;
Vue.prototype.$dayjs = dayjs;

Vue.prototype.$dateFormat = dateFormat;
//新加配置代码
Vue.prototype.$store = store;
const TcPlayer = window.TcPlayer;
Vue.prototype.TcPlayer = TcPlayer;
//close
const BrowserLogger = require("alife-logger");
// BrowserLogger.singleton(conf) conf传入config配置。
// eslint-disable-next-line no-unused-vars
const bl = BrowserLogger.singleton({
  pid: "g2t7afypx3@a71a3d04b8450ea",
  // 设定日志上传地址：
  // 部署新加坡地域可设为`https://arms-retcode-sg.aliyuncs.com/r.png?`。
  // 部署美西地域可设为`http://arms-us-west-1.console.aliyun.com/r.png?`。
  imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
  // enableLinkTrace:true,
  // enableApiCors: true,
  // 其他config配置。
});
Promise.all([SDK.setLayoutToRouter(router, store)])
  .then(() => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#wdc-app");
  })
  .catch((res) => {
    console.log(res);
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#wdc-app");
  });
