
const routerArr = [
  // {
  //     path: '/',
  //     meta: { title:'登录' , login:false },
  //     redirect: { path: '/login' },
  //     component: () => import(/* webpackChunkName: 'entrance' */ './page/login.vue'),
  // },
  {
      path: '/login',
      name: 'login',
      meta: { title:'登录' , login:false },
      component: () => import(/* webpackChunkName: 'entrance' */ './page/login.vue'),
  },
  
]
export default routerArr
