export default API => ({
  // 获取首页 资讯列表、最新课程、轮播
  getHomeData({
    data,
    params
  }) {
    return API({
      url: `/integration/v1/homepage/findByCondition`,
      params,
      data,
      method:'post'
    });
  },
  // 获取首页排行榜
  getUserTopCount({
    data,
    params
  }) {
    return API({
      url: `/ssbi/v1/statistics/userTopCount`,
      params,
      data,
      method:'post'
    });
  },
  myStudyStatistics(data) {
    let params = {
        hasCredit:false,//是否包括学分
        hasExamCount:false,//是否包含考试数量
        hasFinishCourseCount:false,//是否包含课程完成数量
        hasUnCompleteCourseCount:false,//是否包含未完成课程数量
        hasUnCompleteExamCount:false,//待完成考试数量
        hasTotalLearningLength:false,//是否包括学习时长
        ...data
    }
    return API({
      url: '/standard-saas/v1/task/open/myStudyStatistics',
      method: 'get',
      params
    })
  },
  myReadOver() {
    return API({
      url: `/standard-saas/v1/task/open/myReadOver`,
    });
  },
  
})