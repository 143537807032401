// import Table from './../layout/table'
export default {
  install: (Vue) => {
    const Templates = require.context('./../layout/', true, /\.vue$/)
 
    // Vue.component(Table.name, Table)
    Templates.keys().forEach(key => {
      const Template = Templates(key).default
      Vue.component(Template.name, Template)
    })
  }
}