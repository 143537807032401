// const urlName = '/exam/v1';
const urlName = '/standard-saas/v1'
export default API => ({
    // 加入指定练习，返回用户试卷ID
    // joinExam(practiceId){
    //     return API({
    //         url:`${urlName}/userPractice/joinExam?practiceId=${practiceId}`,
    //         method:'get',
    //     })
    // },

    // 加入指定练习，返回用户试卷ID
    userPracticejoinExamJJ({ params,data }) {
        return API({
            url: `${urlName}/practice/joinPractice`,
            method: 'post',
            params,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    joinExam({ data, params }) {
        return API({ url: `${urlName}/userPractice/joinExam`, data, params })
    },
    // 考试中-试题信息
    findQuestForExam(userPracticeId) {
        return API({
            url: `${urlName}/userPractice/findQuestForExam?userPracticeId=${userPracticeId}`,
            method: 'get',
        })
    },
    // 整体提交试卷答案
    commitAllPaper(data, params) {
        return API({
            url: `${urlName}/practice/commitAllPaper`,
            method: 'post',
            data,
            params
        })
    },
    // 单次考试结果-详情
    findUserPaperInfo(params,) {
        return API({
            url: `${urlName}/practice/findUserPaperInfo`,
            method: 'get',
            params,
        })
    },
    //练习列表
    findUserExams({ data, params }) {
        return API({ url: `${urlName}/userPracticeX/findUserExams`, data, params })
    },
    //练习模式
    modelInfo({ data, params }) {
        return API({ url: `${urlName}/practice/modelInfo`, data, params })
    },
    // 单次提交练习试卷答案
    commitPaper(data) {
        return API({
            url: `${urlName}/practice/commitPaper`,
            method: 'post',
            data
        })
    },
    // 问题标记接口(mark:(0:取消标记;1:标记))
    questionMark(data,params) {
        return API({
            url: `${urlName}/practice/question/mark`,
            method: 'post',
            data,
            params,
        })
    }
})