// 课程类型
const courseType = {
  // cls：类名
  1: {
    text: "视频",
    icon: 'shipin',
    type:'videoType'
  },
  2: {
    text: "音频",
    icon: 'audio',
    type:'audioType'
  },
  3: {
    text: '文档',
    icon: 'doc',
    type:'docType'
  },
  4: {
    text: '图片',
    icon: 'pic',
    type:'imgType'
  },
  6: {
    text: 'scorm',
    icon: 'scorm',
    type:'scormType'
  },
}
// 任务类型
// 1 目录，2 素材， 3 考试，4 直播，5 实训, 6 表单，7练习,8线下实训 9 课程
const taskType = {
  1: {
    text: '目录',
    icon: '',
    tpye:''
  },
  2: {
    text: '素材',
    icon: '',
    type:''
  },
  3: {
    text: '考试',
    icon: '',
    type:'examType'
  },
  4: {
    text: '直播',
    icon: '',
    type:''
  },
  5: {
    text: '实操作业',
    icon: '',
    type:''
  },
  6: {
    text: '问卷',
    icon: '',
    type:'questionType'
  },
  7: {
    text: '练习',
    icon: '',
    type:''
  },
  8: {
    text: '线下实训',
    icon: '',
    type:''
  },
  9:{
    text:'课程',
    icon: '',
    type:''
  }
}
export default {
  courseType,
  taskType
}