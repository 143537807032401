export default (API) => ({
  // 列表
  getLecturerList({ params, data }) {
    return API({
      url: "/standard-saas/v1/teacher/findByCondition",
      method: "post",
      params,
      data,
    });
  },
  //查询当前机构下老师的等级
  findTeacherLevel({ params, data }) {
    return API({
      url: `/standard-saas/v1/teacherLevel/findTeacherLevel`,
      method: "get",
      params,
      data,
    });
  },
  //讲师详情
  stuFindByUcode({ params, data }) {
    return API({
      url: `/standard-saas/v1/teacher/stuFindByUcode`,
      method: "get",
      params,
      data,
    });
  },
  // 评分
  saveScore({ data }) {
    return API({
      url: "/standard-saas/v1/teacherEvaluate/save",
      method: "post",
      data,
    });
  },
  // 查询讲师下的课程
  getCourseListByUcode({ params, data }) {
    return API({
      url: "/standard-saas/v1/teacher/getCourseListByUcode",
      method: "get",
      params,
      data,
    });
  },
});
